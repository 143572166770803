<template>
  <basic-page-layout>
    <template v-if="tourData">
      <transition-group name="fade">
        <div v-if="show && tourData['epapp:components'] && tourData['epapp:components'].length" class="ion-margin-bottom">
          <div v-for="(widget, key) in tourData['epapp:components']"
               :key="'tour-widget'+key">

            <widget-selector :type="widget['@type']" :widget-content="widget"></widget-selector>

          </div>
        </div>
      </transition-group>
    </template>
  </basic-page-layout>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import BasicPageLayout from '@/views/basic-page-layout.vue'
import WidgetSelector from '@/widgets/widget-selector.vue';
import { defer } from 'lodash-es';


import {mapState} from 'vuex';
import {AppState} from '@/store-management/vuex';

export default defineComponent({
  extends: BasicPageLayout,

  name: 'TourMenuView',
  components: {
    BasicPageLayout,
    WidgetSelector
  },

  computed: {
    tourId(): string {
      return this.$route.params.tour?.toString();
    },
    ...mapState({
      tourData(state: AppState) {
        return state.tours[this.tourId] || null;
      }
    })
  },

  data() {
    return {
      show: false,
    }
  },

  methods: {
    showContents() {
      this.show = true;
    }
  },

  watch: {
    'tourData': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          defer(this.showContents);
        }
      }
    }
  },

})
</script>

<style scoped lang="scss">

</style>

