
import {defineComponent} from 'vue';
import BasicPageLayout from '@/views/basic-page-layout.vue'
import WidgetSelector from '@/widgets/widget-selector.vue';
import { defer } from 'lodash-es';


import {mapState} from 'vuex';
import {AppState} from '@/store-management/vuex';

export default defineComponent({
  extends: BasicPageLayout,

  name: 'TourMenuView',
  components: {
    BasicPageLayout,
    WidgetSelector
  },

  computed: {
    tourId(): string {
      return this.$route.params.tour?.toString();
    },
    ...mapState({
      tourData(state: AppState) {
        return state.tours[this.tourId] || null;
      }
    })
  },

  data() {
    return {
      show: false,
    }
  },

  methods: {
    showContents() {
      this.show = true;
    }
  },

  watch: {
    'tourData': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          defer(this.showContents);
        }
      }
    }
  },

})
